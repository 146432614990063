import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import styles from "./Table.module.scss";
import { Loader } from "../../Loader/Loader";

/* Список призов на главной */

export function DefaultTable(props) {
  const {
    headerData,
    bodyData,
    isTableLoading,
    className = "",
    withActions = false,
    onCellClick
  } = props;

  return (
    <>
      {isTableLoading ? (
        <Loader />
      ) : Array.isArray(bodyData) && bodyData.length ? (
        <div
          style={{ overflowX: "auto", marginBottom: "30px" }}
          className={className || ""}
        >
          <table className={styles.table}>
            <thead className={styles.thead}>
              <tr className={styles.tableHead}>
                {Object.values(headerData).map((header, index) => (
                  <th
                    key={index + header}
                    className={`${styles.headCell} ${styles.cell}`}
                  >
                    {header}
                  </th>
                ))}
                ,
              </tr>
            </thead>
            <tbody>
            {/* JSON.stringify(headerData) */}
              {bodyData.map((rowItem, itemIndex) => (
                <tr key={itemIndex} className={styles.row}>
                  
                  {Object.keys(headerData).map((key, index) => 
                    key === 'action' ? 
                    <td onClick={() => onCellClick(rowItem)} key={itemIndex + index} className={styles.cell}>{ rowItem[key] }</td> : 
                    <td key={itemIndex + index} className={styles.cell}>{ rowItem[key] }</td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
